import {
  type ParentComponent,
  createSignal,
  createMemo,
  onMount,
  createEffect,
  on,
} from "solid-js";
import {
  useIsRouting,
  createAsync,
  useAction,
  useSubmission,
  query,
  useBeforeLeave,
} from "@solidjs/router";
import {
  SiteContext,
  SessionContext,
  ErrorContext,
  type ErrorCtx,
} from "~/utils/contexts";
import { createScrollableNav } from "~/utils/scroll";
import { createBreakpoints } from "@solid-primitives/media";
import createAnalytics from "@solid-primitives/analytics";
import { trackGTAG, trackFBQ } from "~/utils/analytics";
import { usePrefersDark } from "@solid-primitives/media";
import { VERSION } from "~/app";
import { getBuilderGlobalData } from "~/services/builder";
import {
  getSession,
  setSession as SS,
  setSessionAction,
  getIsPartner,
  logoutAction,
} from "~/services/session";
import {
  useFavourites,
  getFavourites,
  addFavouriteAction,
  deleteFavouriteAction,
} from "~/services/favourites";
import { RomaSessionData } from "~/services/session/session";
import { PERMISSION, type Permission } from "~/services/roma-api/account/types";
import { arrowTrendingUp } from "solid-heroicons/solid";
import { FavouriteTypeOption } from "~/services/roma-api/favourites/types";

import {
  updateCurrentCartAction,
  createCartAction,
  addToCartAction,
  removeFromCartAction,
  deleteCartAction,
  clearCartAction,
  editCartItemAction,
  submitCheckoutAction,
} from "~/services/cart";

import { createPermissionChecker } from "~/services/permissions";
import { createRecentlySearched } from "~/utils/createRecentlySearched";
import {
  type RecentlyViewedProduct,
  createRecentlyViewed,
} from "~/utils/createRecentlyViewed";

import { makePersisted } from "@solid-primitives/storage";
import { createStore } from "solid-js/store";
import { isServer } from "solid-js/web";
import { PT } from "~/utils/products";

import * as Sentry from "@sentry/browser";
import type { ErrorNotification } from "~/utils/contexts";
import { APIError } from "~/services/roma-api/errors";

// ! PANEL CONTROL

export type PanelControlProps = {
  open: boolean;
  mode: "fav" | "cart";
};

const [panel, setPanel] = createSignal<PanelControlProps>({
  open: false,
  mode: "cart",
});

const checkPermission = async (permissions: Permission[] | Permission) => {
  try {
    const session = await getSession();
    if (!session || !session.permissions) throw new Error();

    const permissionSet = new Set(session.permissions);
    const permissionsToCheck = Array.isArray(permissions)
      ? permissions
      : [permissions];

    return permissionsToCheck.every((permission) =>
      permissionSet.has(permission)
    );
  } catch (error) {
    return false;
  }
};

export const hasPermission = query(
  (permissions: Permission[] | Permission) => checkPermission(permissions),
  "permission"
);

export const SiteContextProviderAndSessionManager: ParentComponent = (
  props
) => {
  const session = createAsync(() => getSession());
  const useSession = (options = { deferStream: false }) =>
    createAsync(() => getSession(), options); //TODO - necessary via context?
  const useLogoutAction = useAction(logoutAction);
  const verifyIsPartner = createAsync(() => getIsPartner(), {
    deferStream: true,
  });
  const sessionSetter = useAction(setSessionAction);

  // * FAVOURITES * //
  const addFav = useAction(addFavouriteAction);
  const delFav = useAction(deleteFavouriteAction);

  const toggleFav = async (
    sku: string,
    type: FavouriteTypeOption,
    id?: string
  ) => {
    if (id) {
      await delFav(id);
    } else {
      await addFav(sku, type);
    }
  };

  const isFavourited = (
    favMap: Map<any, any> | undefined,
    sku: string,
    type: FavouriteTypeOption
  ) => {
    if (!favMap) return false;
    return favMap.has(`${sku}-${type}`);
  };

  //* PERMISSION *//
  const permission = createPermissionChecker();

  //* CART PENDING *//
  const updateCart = useAction(updateCurrentCartAction);
  const updatingCart = useSubmission(updateCurrentCartAction);

  const createCart = useAction(createCartAction);
  const creatingCart = useSubmission(createCartAction);

  const addToCart = useAction(addToCartAction);
  const addingToCart = useSubmission(addToCartAction);

  const deleteCart = useAction(deleteCartAction);
  const deletingCart = useSubmission(deleteCartAction);

  const clearCart = useAction(clearCartAction);
  const clearingCart = useSubmission(clearCartAction);

  const removeFromCart = useAction(removeFromCartAction);
  const removingFromCart = useSubmission(removeFromCartAction);

  const editingCartItem = useSubmission(editCartItemAction);

  const submittingCart = useSubmission(submitCheckoutAction);

  // pass cart functions as actions through context? ie cart.addLine(...), cart.deleteLine(...)

  const aCartActionIsPending = createMemo(
    () =>
      !!(
        updatingCart.pending ||
        creatingCart.pending ||
        addingToCart.pending ||
        removingFromCart.pending ||
        clearingCart.pending ||
        deletingCart.pending ||
        editingCartItem.pending ||
        submittingCart.pending
      )
  );

  return (
    <SessionContext.Provider
      value={{
        session,
        setSession: sessionSetter,
        clearSession: useLogoutAction,
        isPartner: verifyIsPartner,
        toggleFav: toggleFav,
        isFavourited,
        aCartActionIsPending,
        permission,
      }}
    >
      <ErrorProvider>
        <SiteContextProvider>{props.children}</SiteContextProvider>
      </ErrorProvider>
    </SessionContext.Provider>
  );
};

const SiteContextProvider: ParentComponent = (props) => {
  const global = createAsync(() => getBuilderGlobalData());
  const headerVisible = createScrollableNav();
  const isLoading = useIsRouting();
  const breakpoints = createBreakpoints({
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1536px",
  });
  const track = createAnalytics([trackGTAG]);
  const isDark = usePrefersDark();
  const [isDarkMode, setIsDarkMode] = createSignal(isDark());

  const [local, setLocal] = makePersisted(
    createStore<{
      recentlySearched: string[];
      recentlyViewed: RecentlyViewedProduct[];
    }>({
      recentlyViewed: [],
      recentlySearched: [],
    }),
    { name: "site-storage" }
  );

  const recentlySearched = createRecentlySearched(local, setLocal, 5);
  const recentlyViewed = createRecentlyViewed(local, setLocal, 8);

  return (
    <SiteContext.Provider
      value={{
        headerVisible,
        isLoading,
        breakpoints,
        track,
        trackFBQ,
        VERSION,
        isDarkMode,
        setIsDarkMode,
        global,
        panel,
        setPanel,
        recentlySearched,
        recentlyViewed,
        local,
      }}
    >
      {props.children}
    </SiteContext.Provider>
  );
};

// * EXPERIMENTAL ERROR NOTIFICATION SYSTEM * //

export type ErrorHandlingResult = {
  notificationId: string;
  sentryEventId?: string;
};

export const ErrorProvider: ParentComponent = (props) => {
  const [notifications, setNotifications] = createStore<ErrorNotification[]>(
    []
  );

  const clearErrors = () => setNotifications([]);

  useBeforeLeave((e) => {
    const from = e?.from?.pathname?.split("?")[0];
    const to = e?.to?.toString()?.split("?")[0];

    // prevent clearing the errors on searchparam changes..
    if (from === to) return;

    clearErrors();
  });

  // Helper to generate error fingerprint
  const getErrorFingerprint = (error: Error): string => {
    if (error instanceof APIError) {
      return `${error.code}-${error.path}-${error.statusCode}`;
    }
    return `${error.name}-${error.message}`;
  };

  // Check if an error is a duplicate
  const isDuplicateError = (error: Error): ErrorNotification | undefined => {
    const fingerprint = getErrorFingerprint(error);
    return notifications.find(
      (n) =>
        n.originalError && getErrorFingerprint(n.originalError) === fingerprint
    );
  };

  const reportErrorToSentry = (error: Error): string => {
    let sentryEventId: string | undefined;

    if (error instanceof APIError) {
      sentryEventId = Sentry.captureException(error, {
        ...error.getSentryContext(),
      });
    } else {
      sentryEventId = Sentry.captureException(error);
    }

    // Update error reporting status if it's an APIError
    if (error instanceof APIError && sentryEventId) {
      error.markAsReported("sentry", { eventId: sentryEventId });
    }

    return sentryEventId;
  };

  const updateNotificationSentryId = (notificationId: string, sentryEventId: string) => {
    setNotifications(
      n => n.id === notificationId, 
      'sentryEventId',
      sentryEventId
    );
  };

  const addError = (
    error: Error,
    config?: Partial<ErrorNotification>
  ): ErrorHandlingResult => {
    // Check for duplicate error
    const existingError = isDuplicateError(error);
    if (existingError) {
      // Update occurrence count for existing error
      setNotifications(
        (n) => n.id === existingError.id,
        "occurrences",
        (count) => count + 1
      );
      // return;
      return {
        notificationId: existingError.id,
        sentryEventId: existingError.sentryEventId,
      };
    }

    // Capture in Sentry (unless suppressed)
    let sentryEventId: string | undefined;
    if (!config?.suppressSentryReport) {
      // ? Can add more granular logic here. Check severity, etc..
      sentryEventId = reportErrorToSentry(error);
    }

    const id = crypto.randomUUID();
    const baseNotification: ErrorNotification = {
      id,
      sentryEventId,
      title: error instanceof APIError ? error.code : "An error occurred",
      message: error.message,
      timestamp: Date.now(),
      severity:
        error instanceof APIError && error.statusCode >= 500
          ? "critical"
          : "warning",
      originalError: error,
      dismissible: true,
      autoDisappear: config?.autoDisappear ?? false,
      autoDisappearDelay: config?.autoDisappearDelay ?? 5000,
      allowFeedback: config?.allowFeedback ?? false,
      showDetails: config?.showDetails ?? false,
      feedbackSubmitted: false,
      occurrences: 1,
    };

    setNotifications((prev) => [{ ...baseNotification, ...config }, ...prev]);
    return {
      notificationId: id,
      sentryEventId,
    };
  };

  const removeError = (id: string) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id));
  };

  // TODO - FIGURE OUT SENTRY
  const submitFeedback = async (id: string, feedback: string) => {
    const notification = notifications.find((n) => n.id === id);
    if (!notification?.sentryEventId) return;

    try {
      // TODO: Implement Sentry feedback submission
      setNotifications((n) => n.id === id, "feedbackSubmitted", true);
    } catch (error) {
      console.error("Failed to submit feedback:", error);
    }
  };

  const value: ErrorCtx = {
    notifications,
    addError,
    removeError,
    submitFeedback,
    clearErrors,
    reportErrorToSentry,
    updateNotificationSentryId
  };

  return (
    <ErrorContext.Provider value={value}>
      {props.children}
    </ErrorContext.Provider>
  );
};
