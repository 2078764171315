import {
  For,
  Show,
  ErrorBoundary,
  Suspense,
  createMemo,
  createEffect,
  onMount,
} from "solid-js";
import { isServer } from "solid-js/web";
import { createAsync, RouteDefinition, useNavigate, A } from "@solidjs/router";
import { getFavourites } from "~/services/favourites";
import { getProducts } from "~/services/products";
import { Product } from "~/components/product";
import { imageUrl } from "~/utils/products";
import { AccountPanel } from "~/components/account";
import { commaSeparatedSkus } from "~/components/header/side-panel/fav-panel/utils";
import type { Product as PType } from "~/services/roma-api/products/types";
import { checkError } from "~/services/roma-api/errors";
import { useErrorContext } from "~/utils/contexts";
import { BaseSkeleton, NotFound } from "~/components/utility";
import { clientOnly } from "@solidjs/start";

const ClientCookieFavouritesMigrator = clientOnly(() =>
  import("~/components/header/side-panel/fav-panel").then((mod) => ({
    default: mod.CookieFavouritesMigrator,
  }))
);

export const route = {
  preload: () => getFavourites(),
} satisfies RouteDefinition;

export default function Favorites() {
  const { addError } = useErrorContext();
  const navigate = useNavigate();
  const favourites = createAsync(
    async () => {
      try {
        const favs = await getFavourites();

        if (favs) {
          const favList = Array.from(favs).map(([key, value]) => {
            const [sku, type] = key.split("-");
            return { sku, type, value };
          });

          // get the product data from api
          const productData = await getProducts({
            skus: commaSeparatedSkus(favList),
            fields: [
              "Name",
              "SKU",
              "Collection",
              "ColourDescription",
              "Category",
              "Width",
              "Height",
              "Rabbet",
              "Face",
              "Depth",
              "IsNew",
              "Profile",
              "Discontinued",
              "Discontinuing",
            ],
            // "name,sku,collection,category,is_new,images,width,height,rabbet,colour_description"
          });

          // Create a map from the product data for faster access
          const productMap = productData.Results.reduce((acc, product) => {
            acc[product.SKU] = product;
            return acc;
          }, {} as Record<string, PType>);

          // merge
          return favList.map((favItem) => ({
            ...favItem,
            productData: productMap[favItem.sku] || null,
          }));
        }
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log("[favorites.tsx]: Error caught in createAsync: ", err);
        }
        addError(err, {
          severity: "warning",
          showDetails: true,
          title: "Favourites Error",
          message:
            "An error occurred while retrieving your favourites. Please try again later. If the problem perists, kindly contact customer support. We apologize for the inconvenience",
          actions: [
            {
              label: "Contact Support",
              action: async () => {
                navigate("/support");
              },
            },
          ],
        });
        throw err;
      }
    },
    { deferStream: true }
  );

  return (
    <AccountPanel>
      <div class="pb-20">
        <h2 class="text-3xl font-bold">Favorites</h2>
        <ErrorBoundary
          fallback={(error, reset) => {
            // TODO - Error-Boundary // make pretty
            console.log("ERROR >> ", error);
            return (
              <div class="mt-8 bg-red-100 rounded-md p-6 flex justify-between">
                <p>Error retrieving account favourites.</p>
                <button type="button" class="text-red-600" onClick={reset}>
                  Reset/Retry
                </button>
              </div>
            );
          }}
        >
          <Suspense fallback={<BaseSkeleton height={400} class="mt-8" />}>
            <section class="grid  sm:grid-cols-3 gap-3 pt-8">
              <ClientCookieFavouritesMigrator />

              <Show
                when={favourites() && favourites()!.length > 0}
                fallback={
                  <>
                    <div class="col-span-full">
                      <NotFound>
                        <div class="p-6 flex flex-col justify-center  h-full gap-3 md:gap-5">
                          <h3 class="text-2xl lg:text-3xl font-bold">
                            Uh-Oh...There's nothing here!
                          </h3>
                          <p>Looks like you haven't added any favorites yet!</p>
                          <ul class="text-sm mt-4 list-disc list-inside">
                            <li>
                              Browse mouldings in the{" "}
                              <A href="/shop" class="text-roma-blue">
                                Shop
                              </A>
                            </li>
                            <li>
                              Check out our{" "}
                              <A
                                href="/shop?collection=Gallery Frames"
                                class="text-roma-blue"
                              >
                                Gallery Frames
                              </A>
                            </li>
                            <li>
                              Explore our{" "}
                              <A href="/new" class="text-roma-blue">
                                Latest Collection!
                              </A>
                            </li>
                          </ul>
                        </div>
                      </NotFound>
                    </div>
                  </>
                }
              >
                <For each={favourites()}>
                  {(item) => (
                    <div class="relative">
                      <Product
                        sku={item.productData.SKU}
                        type={item.type}
                        is_new={item.productData.IsNew}
                        is_discontinued={item.productData.Discontinued}
                        is_discontinuing={item.productData.Discontinuing}
                        image1={imageUrl(item.productData.SKU, item.type, 1)}
                        image2={imageUrl(item.productData.SKU, item.type, 5)}
                        hover={false}
                        colour={item.productData.ColourDescription}
                        collection={item.productData.Collection}
                        category={item.productData.Category}
                        profile={item.productData.Profile}
                        width={item.productData.Width}
                        height={item.productData.Height}
                        rabbet={item.productData.Rabbet}
                      />
                      {/* TODO - add order button, potentially quick-order menu ? ie. length/rail/chop etc... */}
                      {/* <button class="w-10 h-10 border border-gray-300 rounded-lg bg-white/20 backdrop-blur-md absolute top-1 right-1 flex justify-center items-center">
                        <span class="sr-only">Order</span>
                        <Icon path={shoppingCart} class="w-6 h-6 text-roma-dark-grey"/>
                      </button> */}
                    </div>
                  )}
                </For>
              </Show>
            </section>
          </Suspense>
        </ErrorBoundary>
      </div>
    </AccountPanel>
  );
}
