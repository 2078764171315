import { Show, ErrorBoundary, Suspense, createSignal } from "solid-js";
import {
  useSearchParams,
  createAsync,
  type RouteSectionProps,
  type RouteDefinition,
  useNavigate,
} from "@solidjs/router";
import { useProduct } from "~/services/products/useProduct";
import { Meta } from "@solidjs/meta";
import { ProductPriceResponse } from "~/services/roma-api/products/types";
import {
  ProductHeader,
  PricingBox,
  ProductDimensionsDetail,
  BoxQty,
  ProductColourSelector,
  ProductDescription,
  ProductDetails,
  DefaultCareInstr,
  InventoryDropdown,
} from "~/components/product/product-page";
import { useSessionContext } from "~/utils/contexts";
import { InfoBox, BaseSkeleton } from "~/components/utility";
import { MOULDING, PT } from "~/utils/products";
import { ProductPageButtons } from "../[sku]";
import { getSession } from "~/services/session";
import { ComingSoonSubscribe } from "~/components/stock-subscription";
import { GenericError } from "~/components/utility/errors";

export const route = {
  preload: ({ params }) =>
    useProduct(params.sku, { available_list: true, suggestions: true }),
} satisfies RouteDefinition;

export type FormattedPricing = ProductPriceResponse & {
  availableAs?: string[];
  defaultPlantLowStock?: boolean;
  anyPlantLowStock?: boolean;
};

export default function DefaultProduct(props: RouteSectionProps) {
  const { isPartner, permission } = useSessionContext();
  const navigate = useNavigate();
  const [params, _] = useSearchParams();

  const product = createAsync(() =>
    useProduct(props.params.sku, {
      available_list: true,
      suggestions: true,
    })
  );

  const currency = createAsync(async () => {
    const session = await getSession();
    return session?.currency;
  });

  const defaultType = () => {
    if (currency() === "CAD" && product.latest?.Category === "Roma Elite") {
      return PT.JOIN;
    }
    return PT.LENGTH;
  };

  return (
    <>
      {/* TODO - Error-Boundary Priority  */}
      <ErrorBoundary
        fallback={(error, reset) => {
          // TODO - Sentry
          return (
            <GenericError error={error} reset={reset} size="sm" />
            // <div class="!border-t-0">
            //   ErrorBoundary in [sku]/index <br /> Error:{" "}
            //   <pre>{error.message}</pre>
            // </div>
          );
        }}
      >
        <div class="!border-t-0">
          <Meta name="Algolia crawler" content="noindex" />
          <ProductHeader product={product} />
          <ErrorBoundary
            fallback={(error, reset) => {
              // TODO - Sentry
              return (
                <div class="bg-red-100 rounded-md p-3 text-sm">
                  <p>
                    Error retrieving pricing information. Please try again
                    later, we apologize for the inconvenience.
                  </p>
                </div>
              );
            }}
          >
            <Suspense
              // fallback={<BaseSkeleton height={221} />}
              fallback={
                <div class="flex flex-col gap-3">
                  <div class="h-[15px]" />
                  <BaseSkeleton height={60} />
                  <div class="h-[20px]" />
                  <BaseSkeleton height={35} delay={400} />
                  <BaseSkeleton height={35} delay={600} />
                </div>
              }
            >
              <Show
                when={
                  isPartner() &&
                  product() &&
                  permission.VIEWPRICING &&
                  !product()?.ComingSoon
                }
              >
                <PricingBox product={product} />
                <Show when={product()?.Discontinued}>
                  <InfoBox class="mt-4" type="warning">
                    <p>
                      This moulding is discontinued -{" "}
                      {(product()?.DiscontinuedDiscount || 80).toString()}%
                      discount will be applied on Length orders over 50 ft.
                    </p>
                  </InfoBox>
                </Show>
                {/* TODO - Wrap in EB */}
                <ErrorBoundary
                  fallback={(err) => {
                    console.log("Error: ", err);
                    return "";
                  }}
                >
                  <Show when={product()?.ComingSoon && isPartner()}>
                    <ComingSoonSubscribe product={product()} />
                  </Show>
                </ErrorBoundary>
              </Show>
              <ProductPageButtons
                hideBuyButton={product()?.ComingSoon && isPartner()}
                product={product()!}
                onOrderClick={() => {
                  const path = new URLSearchParams();
                  path.set("sku", product()?.SKU as string);
                  path.set("type", (params.type as string) ?? defaultType());
                  if (
                    product()?.ProductType === "Stretcher" &&
                    params.orientation
                  ) {
                    path.set("orientation", params.orientation as string);
                  }
                  navigate(`/order?${path}`);
                }}
              />
            </Suspense>
          </ErrorBoundary>
        </div>

        <ProductDimensionsDetail product={product} />
        <ErrorBoundary
          fallback={(error, reset) => {
            // TODO - Sentry
            return (
              <div class="bg-red-100 rounded-md p-3 text-sm">
                <p>
                  Error retrieving box details. Please try again later, we
                  apologize for the inconvenience.
                </p>
              </div>
            );
          }}
        >
          <Suspense
            fallback={<BaseSkeleton height={50} class="my-4" delay={200} />}
          >
            <Show when={isPartner()}>
              <BoxQty product={product} sku={props.params.sku} />
            </Show>
          </Suspense>
        </ErrorBoundary>
        <div class="grid grid-cols-product-headers items-center text-sm">
          <p class="font-bold">Finish</p>
          <div class="flex items-baseline gap-3 font-medium text-sm tracking-wider">
            <p>{product()?.Finish}</p>
          </div>
        </div>

        <ProductColourSelector product={product} type={MOULDING} />

        <Show when={isPartner()}>
          <ErrorBoundary
            fallback={(error, reset) => {
              // TODO - Sentry
              return (
                <div class="bg-red-100 rounded-md p-3 text-sm">
                  <p>
                    Error retrieving inventory levels. Please try again later,
                    we apologize for the inconvenience.
                  </p>
                </div>
              );
            }}
          >
            <Suspense
              fallback={<BaseSkeleton height={50} class="my-4" delay={400} />}
            >
              <InventoryDropdown product={product} sku={props.params.sku} />
            </Suspense>
          </ErrorBoundary>
        </Show>
        <ProductDescription description={product()?.Description as string} />
        <ProductDetails product={product} openOnLoad={true} />
        <DefaultCareInstr />
      </ErrorBoundary>
    </>
  );
}
