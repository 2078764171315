//? TODO import CornerSampleShop from "~/components/ecommerce/corners/CornerSampleShop";
import {
  Show,
  For,
  createSignal,
  createMemo,
  createEffect,
  Switch,
  Match,
  on,
  onMount,
  onCleanup,
  Suspense,
  ErrorBoundary,
  type Accessor,
} from "solid-js";
import {
  A,
  useSearchParams,
  createAsync,
  RouteSectionProps,
  RouteDefinition,
  useNavigate,
} from "@solidjs/router";
import { isServer } from "solid-js/web";
import { EventType } from "@solid-primitives/analytics";
import {
  useSiteContext,
  useSessionContext,
  useErrorContext,
} from "~/utils/contexts";
import { getBuilderShopDetails, promoCatDict } from "~/services/builder";
import { getProducts } from "~/services/products";
import { Icon } from "solid-heroicons";
import {
  squares_2x2 as squares_2x2outline,
  squaresPlus as squaresPlusOutline,
  heart,
  adjustmentsHorizontal,
  chevronRight,
} from "solid-heroicons/outline";
import {
  squares_2x2,
  squaresPlus,
  heart as heartSolid,
} from "solid-heroicons/solid";
import { Product as ProductCard } from "~/components/product";
import Body from "~/components/Body";
import Breadcrumb from "~/components/Breadcrumb";
import { Accordion } from "~/components/ui";
import { SelectBox } from "~/components/inputs";
import { profileIcons } from "~/utils/icons";
import {
  CollectionSlider,
  RecentlyViewed,
  RangeSelector,
  FilterPanel,
  ProductPromo,
} from "~/components/shop";
import { MetaDetails } from "~/components/utility";
import { type ShopSearchParams } from "~/utils/types";
import type {
  ListResponse,
  Product,
  ProductFilters,
} from "~/services/roma-api/products/types";
import type {
  CataloguePromoModel,
  CataloguePromoModelWithCategoryList,
  CategoryModel,
  CollectionModel,
  ShowCategoryKey,
} from "~/services/builder/types";
import {
  MOULDING,
  PHOTOFRAME,
  GALLERYFRAME,
  MIRROR,
  CONTRACT,
  imageUrl,
} from "~/utils/products";
import { PERMISSION } from "~/services/roma-api/account/types";
import CornerSampleShop from "~/components/shop/corner-sample-shop/CornerSampleShop";
import { getFavourites } from "~/services/favourites";
import { BaseSkeleton } from "~/components/utility";
import { checkError } from "~/services/roma-api/errors";
import { roundDownToQuarter, roundUpToQuarter } from "~/utils/helpers";

const sortingAlgos: Record<string, (a: Product, b: Product) => number> = {
  alpha: (a, b) => a.Collection.localeCompare(b.Collection),
  sku: (a, b) => parseInt(a.SKU) - parseInt(b.SKU),
  colour: (a, b) => a.Colour.localeCompare(b.Colour),
  lowToHigh: (a, b) => (a.Price && b.Price ? a.Price - b.Price : 0),
  highToLow: (a, b) => (a.Price && b.Price ? b.Price - a.Price : 0),
  is_new: (a, b) => (b.IsNew ? 1 : 0) - (a.IsNew ? 1 : 0),
};

const buildFiltersFromParams = (
  params: Partial<ShopSearchParams>
): ProductFilters => {
  const obj: ProductFilters = { limit: 9999, sort: "alpha", counts: true };

  if (params.samples) {
    obj.available_as = "cornerSample";
  }
  if (params.archive) {
    obj.status = "archive";
  }
  if (params.on_sale) {
    // ! TEST
    obj.on_sale = true;
  }
  if (params.skus) {
    obj.skus = params.skus;
  }

  if (params.category) {
    switch (params.category) {
      case "Mirrors":
        obj.available_as = "mirror";
        break;
      case "Gallery Frames":
        obj.available_as = "galleryFrame";
        break;
      case "Photo Frames":
        obj.available_as = "photoFrame";
        break;
      case "Roma Contract":
        obj.available_as = "contract";
        break;
      case "New Releases":
        obj.sort = "is_new";
        break;
      case "Clearance": //TODO: Remove clearance - leave in temporarily for compatibility while switching to 'Leaving Soon'
      case "Leaving Soon":
        obj.status = ["active", "discontinued"];
        break;
      case "Fillets":
        obj.profile = "Fillet";
        break;
      case "Stretchers":
        obj.profile = "Stretcher";
        break;
      case "Floaters":
        obj.profile = "Floater";
        break;
      default:
        obj.category = params.category;
    }
  }

  obj.fields = [
    "Name",
    "SKU",
    "Collection",
    "IsNew",
    "Discontinued",
    "Profile",
    "Discontinuing",
    "Height",
    "Width",
    "Rabbet",
    "Face",
    "Depth",
    "Images",
    "ColourDescription",
    "Colour",
    "Price",
    "Finish",
    "FinishType",
    "ComingSoon",
    "ArrivalDate",
    "OnSale",
  ];

  return obj;
};

export const route = {
  preload: ({ location }) => {
    const filters = buildFiltersFromParams(location.query);
    return getProducts(filters);
  },
} satisfies RouteDefinition;

const Shop = (props: RouteSectionProps) => {
  const { isPartner, session, permission } = useSessionContext();
  const { track, breakpoints } = useSiteContext();
  const { addError } = useErrorContext();
  const [pageError, setPageError] = createSignal();
  const [params, setParams] = useSearchParams<ShopSearchParams>();
  const [gridSmall, setGridSmall] = createSignal(false);
  const [showFilters, setShowFilters] = createSignal(true);
  const navigate = useNavigate();
  const [limitProducts, setLimitProducts] = createSignal(!isPartner());
  const productLimit = 48;
  let lowerBound: HTMLDivElement;

  // Data
  const products = createAsync(async () => {
    const filters = buildFiltersFromParams(params);

    try {
      return await getProducts(filters);
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[shop/index]: Error caught in createAsync: ", err);
      }
      addError(err, {
        severity: "critical",
        showDetails: true,
        title: "Error retrieving products",
        message:
          "An Error occurred while retrieving product information. Please try again shortly. If this error persists, kindly contact customer support.",
        actions: [
          {
            label: "Contact Support",
            action: async () => {
              navigate("/support");
            },
          },
        ],
      });

      setPageError(true);
    }
  });
  const details = createAsync(async () => {
    const details = await getBuilderShopDetails();
    // if corner sample shop, remove GF/PF from categories
    if (params.samples) {
      details.data.categories = details.data.categories.filter(
        (category) =>
          !["Gallery Frames", "Photo Frames"].includes(category.name)
      );
    }

    const headerTitles: Record<
      string,
      CategoryModel["data"] | CollectionModel["data"]
    > = {};
    // iterate over categories and collections, add to headerTitles dict,
    for (const category of details.data.categories) {
      headerTitles[category.name] = category.data;
    }
    for (const collection of details.data.collections) {
      headerTitles[collection.name] = collection.data;
    }

    // iterate over the catalogue promos, then over each key of the 'showCategory'
    // object - for any category that the promo should be active in, turn the camelCase key
    // into the display value using promoCatDict, and add those to a new property 'showCategoryList'
    const promoArr: CataloguePromoModelWithCategoryList[] =
      details.data.promos.map((promo) => {
        const list = Object.keys(promo.data.showCategory).map((item) => {
          const key = item as ShowCategoryKey;
          if (promo.data.showCategory[key]) {
            return promoCatDict[item];
          }
        });
        // add new property into promo data, 'showCategoryList'
        return { ...promo, data: { ...promo.data, showCategoryList: list } };
      });

    return { ...details.data, headerTitles, promos: promoArr };
  });
  // TODO - improve favourites display
  const favs = createAsync(() => getFavourites());

  onMount(() => {
    if (params.samples || params.archive) {
      if (!isPartner()) {
        navigate("/?signIn=true");
      }
    }
    if (!isServer && lowerBound && ioProductLimit) {
      ioProductLimit.observe(lowerBound);
    }
    onCleanup(() => {
      if (!isServer && lowerBound && ioProductLimit)
        ioProductLimit.unobserve(lowerBound);
    });
  });

  createEffect(() => {
    params.category == "Roma Elite" ? setGridSmall(true) : null;
  });

  createEffect(() => {
    if (!breakpoints.md) {
      setShowFilters(false);
    }
  });

  // Memos
  const hasViewPricingPermission = createMemo(() => {
    return permission.VIEWPRICING;
  });
  const activePromos = createMemo(() => {
    if (!details()?.promos || details()?.promos!?.length <= 0) return [];
    // filtering for active promos:
    const filteredActive = [...details()!.promos].filter(
      (promo) => promo.data.active
    );
    if (filteredActive.length === 0) {
      return [];
    }
    // filtering active promos for promos that match the current category, collection, or are marked 'all':
    const filteredMatching = filteredActive.filter((promo) => {
      if (
        (promo.data.showCategoryList.includes("All") ||
          (!!params.category &&
            promo.data.showCategoryList.includes(params.category)) ||
          (!!params.collection &&
            promo.data?.showCollection?.includes(params.collection))) &&
        !params.samples
      ) {
        return true;
      }
      return false;
    });

    return filteredMatching;
  });
  const collectionsInCurrentCategory = createMemo(() => {
    const prods = products() as ListResponse<Product> & {
      Collections: Record<string, number>;
      Categories: Record<string, number>;
      Profiles: Record<string, number>;
      Colours: Record<string, number>;
    };
    const deets = details();

    if (!prods || !deets) return [];

    const obj = prods.Results.reduce(
      (memo, product) => {
        if (memo[product.Collection]) {
          memo[product.Collection].qtyCount += 1;
          return memo;
        }
        if (!memo[product.Collection]) {
          const collection = deets.headerTitles[
            product.Collection
          ] as CollectionModel["data"];
          if (!collection || collection.hideOnCollectionPage) {
            return memo;
          }
          memo[product.Collection] = {
            image: collection.singleMouldingImage,
            photoFrameImage: collection.photoFrameImage,
            galleryFrameImage: collection.galleryFrameImage,
            count: prods.Collections[product.Collection],
            qtyCount: 1,
          };
        }
        return memo;
      },
      {} as Record<
        string,
        {
          image: string;
          photoFrameImage: string;
          galleryFrameImage: string;
          count: number;
          qtyCount: number;
        }
      >
    );

    const arr = Object.keys(obj).map((item) => {
      return {
        name: item,
        img: obj[item].image,
        photoFrameImage: obj[item].photoFrameImage,
        galleryFrameImage: obj[item].galleryFrameImage,
        count: obj[item].count,
        qtyCount: obj[item].qtyCount,
      };
    });
    return arr;
  });
  const sizes = createMemo(
    on(products, () => {
      const ranges = {
        width: [0, 0],
        height: [0, 0],
        rabbet: [0, 0],
      };
      if (products()) {
        for (const product of products()!.Results) {
          ranges.width[0] = Math.min(ranges.width[0], product.Width);
          ranges.width[1] = Math.max(ranges.width[1], product.Width);
          ranges.height[0] = Math.min(ranges.height[0], product.Height);
          ranges.height[1] = Math.max(ranges.height[1], product.Height);
          ranges.rabbet[0] = Math.min(ranges.rabbet[0], product.Rabbet);
          ranges.rabbet[1] = Math.max(ranges.rabbet[1], product.Rabbet);
        }
      }
      return ranges;
    })
  );
  const priceRange = createMemo(
    on(products, () => {
      const range = [0, 0];
      if (products()?.Results) {
        for (const product of products()!.Results) {
          if (product.Price) {
            range[0] = Math.min(range[0], product.Price);
            range[1] = Math.max(range[1], product.Price);
          }
        }
      }
      return range;
    })
  );
  // TODO deal with sessin.favourites..
  const filteredProducts = createMemo(() => {
    if (!products()) return undefined;
    let prods = [...products()!.Results];
    let favourites = null;
    if (params.showFavourites && favs()) {
      // TODO! DISABLED
      // need to deal with the different product types better..

      favourites = new Set();
      // const list = JSON.parse(session.favourites);
      const list = Array.from(favs() as Map<any, any>);
      for (let fave of list) {
        const sku = fave[0].split("-")[0];
        favourites.add(sku);
      }
    }
    const collections = params.collection ? params.collection.split("~") : null;
    const profiles = params.profile ? params.profile.split("~") : null;
    const colours = params.colour ? params.colour.split("~") : null;
    const minPrice = params.minPrice ? parseFloat(params.minPrice) : null;
    const maxPrice = params.maxPrice ? parseFloat(params.maxPrice) : null;
    const minWidth = params.minWidth ? parseFloat(params.minWidth) : null;
    const maxWidth = params.maxWidth ? parseFloat(params.maxWidth) : null;
    const minHeight = params.minHeight ? parseFloat(params.minHeight) : null;
    const maxHeight = params.maxHeight ? parseFloat(params.maxHeight) : null;
    const minRabbet = params.minRabbet ? parseFloat(params.minRabbet) : null;
    const maxRabbet = params.maxRabbet ? parseFloat(params.maxRabbet) : null;
    // TODO: Remove 'Clearance' check below -> Leave in temporarily for backwards compatibility while switching to 'Leaving Soon'
    const discontinued =
      params.category &&
      (params.category == "Leaving Soon" || params.category == "Clearance")
        ? true
        : false;
    const archived = params.archive ? true : false;
    const finishes = params.finish ? params.finish.split("~") : null;

    prods = prods.filter((product) => {
      const inCollection =
        collections == null || collections.includes(product.Collection)
          ? true
          : false;
      const inColour =
        colours == null || colours.includes(product.Colour) ? true : false;
      const inProfiles =
        profiles == null || profiles.includes(product.Profile) ? true : false;
      const inMinPrice = minPrice == null || product.Price >= minPrice;
      const inMaxPrice = maxPrice == null || product.Price <= maxPrice;
      const inMinWidth = minWidth == null || product.Width >= minWidth;
      const inMaxWidth = maxWidth == null || product.Width <= maxWidth;
      const inMinHeight = minHeight == null || product.Height >= minHeight;
      const inMaxHeight = maxHeight == null || product.Height <= maxHeight;
      const inMinRabbet = minRabbet == null || product.Rabbet >= minRabbet;
      const inMaxRabbet = maxRabbet == null || product.Rabbet <= maxRabbet;
      const inFavourites = favourites == null || favourites.has(product.SKU);
      const inDiscontinued =
        discontinued || archived ? product.Discontinued : !product.Discontinued;
      const inFinishes =
        finishes == null || finishes.includes(product.Finish) ? true : false;

      return (
        inCollection &&
        inColour &&
        inProfiles &&
        inMinWidth &&
        inMaxWidth &&
        inMinHeight &&
        inMaxHeight &&
        inMinRabbet &&
        inMaxRabbet &&
        inFavourites &&
        inDiscontinued &&
        inMinPrice &&
        inMaxPrice &&
        inFinishes
      );
    });
    if (params.sort && sortingAlgos[params.sort] !== null) {
      prods.sort(sortingAlgos[params.sort]);
    }

    // if any active promos, inject them into the product list:
    if (activePromos().length > 0) {
      activePromos().forEach((item) => {
        prods.splice(item.data.position, 0, {
          ...item,
          // @ts-expect-error
          contentType: "promo",
        });
      });
    }

    return prods;
  });
  const filters = createMemo(() => {
    if (!products() || !filteredProducts() || !details()) return {};
    let collectionQuantity: Map<string, number> = new Map();
    let coloursQuantity: Map<string, number> = new Map();
    let profilesQuantity: Map<string, number> = new Map();
    let finishesQuantity: Map<string, number> = new Map();

    for (let product of products()!.Results) {
      collectionQuantity.set(
        product.Collection,
        (collectionQuantity.get(product.Collection) || 0) + 1
      );
      coloursQuantity.set(
        product.Colour,
        (coloursQuantity.get(product.Colour) || 0) + 1
      );
      profilesQuantity.set(
        product.Profile,
        (profilesQuantity.get(product.Profile) || 0) + 1
      );
      finishesQuantity.set(
        product.Finish,
        (finishesQuantity.get(product.Finish) || 0) + 1
      );
    }

    return {
      collections: details()?.collections.reduce((memo, { name }) => {
        if (collectionQuantity.has(name)) {
          memo.push({
            image: "",
            label: name,
            quantity: collectionQuantity.get(name),
          });
        }
        return memo;
      }, [] as { image: string; label: string; quantity?: number }[]),
      colours: details()?.colours.reduce((memo, { name, data }) => {
        if (coloursQuantity.has(name)) {
          memo.push({
            colour: data.colour,
            metallic: data.gradient,
            label: name,
            quantity: coloursQuantity.get(name),
          });
        }
        return memo;
      }, [] as { colour: string; metallic: boolean; label: string; quantity?: number }[]),
      profile: details()?.profiles.reduce((memo, { name }) => {
        if (profilesQuantity.has(name)) {
          memo.push({
            image: profileIcons[name],
            label: name,
            selected: false,
            value: "",
            quantity: profilesQuantity.get(name),
          });
        }
        return memo;
      }, [] as { image: string; label: string; selected: boolean; value: string; quantity?: number }[]),
      finish: [...finishesQuantity].reduce((memo, [name, qty]) => {
        memo.push({
          label: name,
          quantity: qty,
          image: "",
        });
        return memo;
      }, [] as { label: string; quantity: number; image: string }[]),
    };
  });
  const productType = createMemo(() => {
    switch (params.category) {
      case "Photo Frames":
        return PHOTOFRAME;
      case "Gallery Frames":
        return GALLERYFRAME;
      case "Mirrors":
        return MIRROR;
      case "Roma Contract":
        return CONTRACT;
      default:
        return MOULDING;
    }
  });
  const setParamsDebounce = (
    minKey: string,
    minVal: number | undefined,
    maxKey: string,
    maxVal: number | undefined
  ) => {
    setParams(
      {
        [minKey]: minVal,
        [maxKey]: maxVal,
      },
      { replace: true }
    );
  };
  const sortByOptions = createMemo(() => {
    const options = [
      { label: "New", value: "is_new" },
      { label: "Alphabetical", value: "alpha" },
      { label: "Numerical", value: "sku" },
      { label: "Colour", value: "colour" },
    ];
    if (isPartner() && hasViewPricingPermission()) {
      options.push(
        { label: "Price High - Low", value: "highToLow" },
        { label: "Price Low - High", value: "lowToHigh" }
      );
    }
    return options;
  });
  const clearFilters = () => {
    setParams({
      collection: undefined,
      colour: undefined,
      profile: undefined,
      finish: undefined,
      minPrice: undefined,
      maxPrice: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      minHeight: undefined,
      maxHeight: undefined,
      minRabbet: undefined,
      maxRabbet: undefined,
    });
  };
  const isActiveFilters = createMemo(() => {
    if (
      params.colour ||
      params.collection ||
      params.finish ||
      params.profile ||
      params.minPrice ||
      params.maxPrice ||
      params.minWidth ||
      params.maxWidth ||
      params.minHeight ||
      params.maxHeight ||
      params.minRabbet ||
      params.maxRabbet
    )
      return true;
    return false;
  });
  const metaInfo = createMemo(() => {
    let desc: string | undefined;
    if (params.category && details()?.headerTitles[params.category]) {
      desc = (details()!.headerTitles[params.category] as CategoryModel["data"])
        .featureDescription;
    }
    if (params.collection && details()?.headerTitles[params.collection]) {
      desc = (
        details()?.headerTitles[params.collection] as CollectionModel["data"]
      ).description;
    }
    let filters = [];
    if (!desc) {
      desc = `Shop for products`;
    }
    if (params.collection) {
      if (params.collection.includes("~")) {
        filters.push(...params.collection.split("~"));
      } else {
        filters.push(params.collection);
      }
    }
    if (params.colour) {
      if (params.colour.includes("~")) {
        filters.push(...params.colour.split("~"));
      } else {
        filters.push(params.colour);
      }
    }
    if (params.profile) {
      if (params.profile.includes("~")) {
        filters.push(...params.profile.split("~"));
      } else {
        filters.push(params.profile);
      }
    }
    let title = `Shop${params.category ? " " + params.category : ""}`;

    if (filters.length !== 0) {
      desc = `${desc ? `${desc}.` : ""} Filtered by ${filters.join(", ")}`;
      title = `${title} ${filters.join(`, `)}`;
    }

    return {
      description: desc,
      title: title,
      keywords: filters,
    };
  });

  // IO to add hover images once they enter viewport
  const io: IntersectionObserver = isServer
    ? ({} as IntersectionObserver)
    : new IntersectionObserver((entries) => {
        for (const { target, isIntersecting } of entries) {
          if (isIntersecting) {
            const img = document.createElement("img");
            img.setAttribute("loading", "lazy");
            img.setAttribute("src", target.getAttribute("data-hover-url")!);
            img.setAttribute("alt", "");
            img.setAttribute(
              "class",
              "w-full opacity-0  group-hover:opacity-100 transition-opacity duration-200"
            );
            target.append(img);
            io.unobserve(target);
          }
        }
      });

  // IO is observing the 'load more products' button when limitProducts is true.
  // When target comes into viewport, setLimitProducts to false (show remaining products).
  // ioProductLimit.observe is called within page's onMount
  const ioProductLimit: IntersectionObserver = isServer
    ? ({} as IntersectionObserver)
    : new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && limitProducts()) {
            setLimitProducts(false);
            ioProductLimit.unobserve(entry.target);
          }
        });
      });

  const breadcrumbs = () => {
    const base = [
      { pageTitle: "Home", url: "/" },
      { pageTitle: "Shop", url: "/shop" },
    ];

    switch (true) {
      case !!params.category:
        base.push({
          pageTitle: params.category,
          url: `/shop?category=${params.category}`,
        });
        break;
      case !!params.collection:
        base.push({
          pageTitle: params.collection,
          url: `/shop?collection=${params.collection}`,
        });
        break;
    }

    return base;
  };

  return (
    <ErrorBoundary
      fallback={(error, reset) => {
        // this isn't really getting hit, probably bc the products resource is
        // being used outside here..rely on explicit error state pageError()
        // to render UI. Fallback can be reworked GenericError
        return <div>An error occurred.</div>;
      }}
    >
      <Suspense fallback={<BaseSkeleton height={400} class="mt-10" />}>
        <Show
          when={filteredProducts() && details()}
          fallback={
            <Show when={pageError()}>
              {/* TODO - Error-Boundary - Priority - Make a nice looking placeholder for this critical error state */}
              <div class="min-h-[40vh] flex items-center justify-center ">
                <div class="bg-roma-grey border rounded-md mx-auto max-w-4xl p-6 my-8">
                  <p>An error occurred while retrieving products</p>
                </div>
              </div>
            </Show>
          }
        >
          <MetaDetails
            title={metaInfo()?.title}
            description={metaInfo()?.description}
            keywords={metaInfo()?.keywords}
          />
          <Body>
            <div class="pt-5 pb-8 px-10">
              <Breadcrumb breadcrumbs={breadcrumbs()} />{" "}
              <div class="w-full my-2 sm:mt-8 flex flex-col-reverse md:flex-row justify-between items-end">
                <Show
                  when={
                    !params.category &&
                    !params.collection &&
                    !params.status &&
                    !params.samples &&
                    !params.archive
                  }
                >
                  <span class="inline-block  text-3xl font-bold">
                    Explore endless possibilities. With over 1500 mouldings to
                    choose from, the only limit is your imagination.
                  </span>
                </Show>
                <Show when={params.samples}>
                  <h3 class="text-4xl sm:text-5xl font-bold">
                    Corner Sample Shop
                  </h3>
                </Show>
                <Show when={params.archive}>
                  <h3 class="text-4xl sm:text-5xl font-bold">
                    Discontinued Archive
                  </h3>
                </Show>
                <Show
                  when={
                    ((params.category &&
                      details()?.headerTitles[params.category]) ||
                      (params.collection &&
                        details()?.headerTitles[params.collection])) &&
                    !params.samples
                  }
                >
                  <div class="w-full text-3xl font-bold">
                    <Switch>
                      <Match
                        when={
                          params.collection &&
                          !params.collection.includes("~") &&
                          !!filteredProducts() &&
                          filteredProducts()!.length > 0 &&
                          !params.category
                        }
                      >
                        <div class="flex max-sm:flex-col-reverse max-sm:gap-6 justify-between sm:items-center w-full">
                          <div class="inline-block ">
                            <h2 class="inline-block max-sm:text-xl md:max-w-4xl">
                              {
                                (
                                  details()?.headerTitles[
                                    params.collection as string
                                  ] as CollectionModel["data"]
                                ).description
                              }
                            </h2>
                          </div>
                          <h3 class="sm:ml-8 text-4xl sm:text-7xl">
                            {params.collection}
                          </h3>
                        </div>
                      </Match>
                      <Match when={params.category && !params.samples}>
                        <h3 class="inline-block md:max-w-4xl">
                          {
                            (
                              details()?.headerTitles[
                                params.category as string
                              ] as CategoryModel["data"]
                            ).featureDescription
                          }
                        </h3>
                      </Match>
                    </Switch>
                  </div>
                </Show>
                <Show when={params.category && !params.samples}>
                  <div class="h-8  md:ml-20">
                    <img
                      class=" h-8"
                      src={
                        (
                          details()?.headerTitles[
                            params.category as string
                          ] as CategoryModel["data"]
                        )?.logo
                      }
                      alt=""
                    />
                  </div>
                </Show>
              </div>
              <Show
                when={
                  !params.collection &&
                  params.category &&
                  ![
                    "Roma Elite",
                    "Roma Contract",
                    "Mirror",
                    "New Releases",
                    "Clearance", //TODO: Remove Clearance - leave in temporarily while switching to 'Leaving Soon'
                    "Leaving Soon",
                    "Stretchers",
                    "Fillets",
                    "Floaters",
                  ].includes(params.category) &&
                  !params.samples
                }
              >
                <CollectionSlider
                  list={collectionsInCurrentCategory()}
                  type={productType()}
                />
              </Show>
              <main class="w-full flex gap-0 items-start mt-2 sm:mt-8 overflow-x-clip ">
                <div
                  class="shrink-0 w-full md:w-[275px] bg-white flex flex-col fixed inset-0 max-md:z-[25] max-md:px-10  md:sticky top-0 max-h-screen child:border-b child:py-4  overflow-y-auto scrollbar-custom transition-all duration-700 pr-0 mr-0 md:pr-6 md:mr-6"
                  classList={{
                    "-translate-x-[350px] !w-[0px] !pr-0 !mr-0": !showFilters(),
                  }}
                >
                  <button
                    class="md:hidden  sticky top-0 backdrop-blur-md -mx-10 bg-roma-grey flex items-center justify-around z-[26]"
                    onClick={() => {
                      setShowFilters(false);
                    }}
                  >
                    <span class="text-roma-medium-grey">
                      {filteredProducts()?.length} Results
                    </span>
                    <div class="flex items-center">
                      <span>Back to Products</span>
                      <Icon path={chevronRight} class="w-5 pb-1" />
                    </div>
                  </button>
                  <Accordion
                    name="category"
                    label="Category"
                    icon="Chevron"
                    labelClass="font-medium"
                    checked
                    contentHeight="Large"
                  >
                    <div class="flex flex-col gap-2">
                      <For each={details()?.categories}>
                        {(category) => (
                          <A
                            href={`/shop/?category=${category.name}${
                              params.samples ? "&samples=true" : ""
                            }${params.archive ? "&archive=true" : ""}${
                              params.sort ? `&sort=${params.sort}` : ""
                            }`}
                            class={`flex items-center hover:text-roma-blue transition-[color] ${
                              params.category == category.name
                                ? "text-roma-blue"
                                : ""
                            }`}
                          >
                            <span>{category.name}</span>
                            <Show when={category.data.isNew}>
                              <div class="ml-3 bg-roma-blue rounded-full px-2 inline-flex items-center">
                                <span class="text-xs text-white mb-0.5">
                                  New
                                </span>
                              </div>
                            </Show>
                          </A>
                        )}
                      </For>
                    </div>
                  </Accordion>
                  <FilterPanel
                    name="collection"
                    param="collection"
                    label="Collection"
                    options={filters().collections}
                    fadeBottom={true}
                  />
                  <FilterPanel
                    name="profile"
                    param="profile"
                    label="Shape"
                    options={filters().profile}
                    fadeBottom={true}
                  />
                  <FilterPanel
                    name="colour"
                    param="colour"
                    label="Colours"
                    options={filters().colours}
                    fadeBottom={true}
                  />
                  <FilterPanel
                    name="finish"
                    param="finish"
                    label="Finish"
                    options={filters().finish}
                    fadeBottom={true}
                  />
                  <Show
                    when={
                      isPartner() &&
                      hasViewPricingPermission() &&
                      priceRange() &&
                      (params.category === undefined ||
                        !["Photo Frames", "Gallery Frames"].includes(
                          params.category
                        ))
                    }
                  >
                    <Accordion
                      name="price"
                      label="Price"
                      icon="Chevron"
                      checked={false}
                    >
                      <RangeSelector
                        min={roundDownToQuarter(priceRange()[0])}
                        currMinVal={params.minPrice}
                        max={roundUpToQuarter(priceRange()[1])}
                        currMaxVal={params.maxPrice}
                        step={0.25}
                        type="price"
                        layout="display"
                        onChange={({ min, max }) => {
                          track(EventType.Event, {
                            category: "catalogue",
                            action: "range_filtered",
                            label: "price",
                            value: `${min}-${max}`,
                          });
                          setParamsDebounce("minPrice", min, "maxPrice", max);
                        }}
                      />
                    </Accordion>
                  </Show>
                  <div class="relative">
                    <Accordion
                      name="size"
                      label="Size"
                      icon="Chevron"
                      checked={true}
                      contentHeight="Large"
                    >
                      <RangeSelector
                        min={sizes().width[0]}
                        currMinVal={params.minWidth}
                        max={sizes().width[1]}
                        currMaxVal={params.maxWidth}
                        step={0.125}
                        label="Width"
                        layout="inputs"
                        onChange={({ min, max }) => {
                          track(EventType.Event, {
                            category: "catalogue",
                            action: "range_filtered",
                            label: "width",
                            value: `${min}-${max}`,
                          });
                          setParamsDebounce("minWidth", min, "maxWidth", max);
                        }}
                      />
                      <RangeSelector
                        min={sizes().height[0]}
                        currMinVal={params.minHeight}
                        max={sizes().height[1]}
                        currMaxVal={params.maxHeight}
                        step={0.125}
                        label="Height"
                        class="mt-10"
                        layout="inputs"
                        onChange={({ min, max }) => {
                          track(EventType.Event, {
                            category: "catalogue",
                            action: "range_filtered",
                            label: "height",
                            value: `${min}-${max}`,
                          });
                          setParamsDebounce("minHeight", min, "maxHeight", max);
                        }}
                      />
                      <RangeSelector
                        min={sizes().rabbet[0]}
                        currMinVal={params.minRabbet}
                        max={sizes().rabbet[1]}
                        currMaxVal={params.maxRabbet}
                        step={0.125}
                        label="Rabbet Height"
                        class="mt-10 pb-32"
                        layout="inputs"
                        onChange={({ min, max }) => {
                          track(EventType.Event, {
                            category: "catalogue",
                            action: "range_filtered",
                            label: "rabbet",
                            value: `${min}-${max}`,
                          });
                          setParamsDebounce("minRabbet", min, "maxRabbet", max);
                        }}
                      />
                    </Accordion>
                  </div>
                </div>
                <Show when={params.samples === "true"}>
                  <Suspense fallback={<BaseSkeleton height={400} />}>
                    <CornerSampleShop
                      list={filteredProducts as Accessor<Product[]>}
                      filterPanel={[showFilters, setShowFilters]}
                      sortByOptions={sortByOptions}
                    />
                  </Suspense>
                </Show>
                <Show when={!params.samples}>
                  <div
                    class="w-full grow shrink-0 md:shrink col-span-8 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-x-3 gap-y-10 "
                    classList={{
                      "lg:grid-cols-12": !gridSmall(),
                      "lg:grid-cols-9": !!gridSmall(),
                    }}
                  >
                    <div class="col-span-full bg-white bg-opacity-80 backdrop-blur-lg py-3 sticky top-0 z-[15] flex max-sm:flex-wrap gap-2 justify-between items-center">
                      <div class="hidden sm:flex items-center gap-2 z-20">
                        <p class="text-roma-dark-grey">
                          {filteredProducts()?.length} Items
                        </p>
                        <Show when={isActiveFilters()}>
                          <button
                            class="text-roma-blue text-sm"
                            onClick={clearFilters}
                          >
                            Clear Filters
                          </button>
                        </Show>
                      </div>
                      <div class="flex max-sm:justify-between max-sm:grow max-sm:flex-wrap items-center gap-2 sm:gap-3">
                        <button
                          class="flex items-center gap-2 border border-gray-300 rounded-full max-sm:grow max-sm:px-3 md:border-none p-2 md:p-0"
                          onClick={() => {
                            setShowFilters(!showFilters());
                          }}
                        >
                          <span class="hidden md:block">
                            {showFilters() ? "Hide " : "Show "}Filters
                          </span>
                          <span>
                            <Icon path={adjustmentsHorizontal} class="w-5" />
                          </span>
                          <p class="inline-block sm:hidden">
                            {filteredProducts()?.length} Items
                          </p>
                        </button>
                        <SelectBox
                          options={sortByOptions()}
                          inlineTitle="Sort By:"
                          // defaultValue={params.sort !== "" ? params.sort : "alpha"}
                          //? leave in - not sure what this was doing, replaced
                          //? defaultValue with below and added controlled 'value'.
                          //? switch back if not working as intended..
                          defaultValue={params.sort}
                          value={{ value: params.sort ?? "alpha" }}
                          onChange={(option) => {
                            track(EventType.Event, {
                              category: "catalogue",
                              action: "sort",
                              value: option.value as string,
                            });
                            setParams(
                              { sort: option.value },
                              { replace: true }
                            );
                          }}
                          class="max-sm:grow"
                          triggerClass="!rounded-full"
                        />
                        <div class="hidden lg:flex items-center gap-3">
                          {/* TODO - Favourites */}
                          {/* <Icon
                            path={
                              params.showFavourites == "true" ? heartSolid : heart
                            }
                            class="w-6 aspect-square cursor-pointer text-roma-blue"
                            onClick={() => {
                              if (
                                params.showFavourites == "false" ||
                                !params.showFavourites
                              ) {
                                setParams({
                                  showFavourites: true,
                                });
                              } else {
                                setParams({ showFavourites: undefined });
                              }
                            }}
                          /> */}
                          <Icon
                            path={
                              gridSmall() ? squares_2x2outline : squares_2x2
                            }
                            class="w-6 aspect-square cursor-pointer"
                            onClick={() => {
                              setGridSmall(false);
                            }}
                          />
                          <Icon
                            path={
                              gridSmall() ? squaresPlus : squaresPlusOutline
                            }
                            class="w-6 aspect-square cursor-pointer"
                            onClick={() => setGridSmall(true)}
                          />
                        </div>
                      </div>
                    </div>
                    <Show
                      fallback={
                        <div class="bg-roma-grey col-span-full text-center p-20">
                          No products were found.
                        </div>
                      }
                      when={filteredProducts()?.length !== 0}
                    >
                      <For
                        each={
                          limitProducts()
                            ? filteredProducts()?.slice(0, productLimit)
                            : filteredProducts()
                        }
                      >
                        {(product) => (
                          <div class="col-span-3">
                            <Show
                              // @ts-expect-error
                              when={product.contentType === "promo"}
                              fallback={
                                <ProductCard
                                  loading="lazy"
                                  sku={product.SKU}
                                  type={productType()}
                                  onLoad={(anchor) => {
                                    !isServer && io.observe(anchor);
                                  }}
                                  onCleanup={(anchor) => {
                                    !isServer && io.unobserve(anchor);
                                  }}
                                  is_coming_soon={product.ComingSoon}
                                  arrival_date={
                                    product.ComingSoon && product.ArrivalDate
                                      ? product.ArrivalDate
                                      : undefined
                                  }
                                  is_on_sale={isPartner() && product.OnSale}
                                  is_new={product.IsNew}
                                  is_discontinued={product.Discontinued}
                                  is_discontinuing={product.Discontinuing}
                                  image1={imageUrl(
                                    product.SKU,
                                    productType(),
                                    1
                                  )}
                                  image2={imageUrl(
                                    product.SKU,
                                    productType() == GALLERYFRAME ||
                                      productType() == PHOTOFRAME
                                      ? productType()
                                      : "mouldings",
                                    productType() == GALLERYFRAME ||
                                      productType() == PHOTOFRAME
                                      ? 2
                                      : 5
                                  )}
                                  hover={true}
                                  colour={product.ColourDescription}
                                  collection={product.Collection}
                                  category={product.Category}
                                  profile={product.Profile}
                                  depth={product.Depth}
                                  face={product.Face}
                                  width={product.Width}
                                  height={product.Height}
                                  rabbet={product.Rabbet}
                                  showPrice={
                                    isPartner() &&
                                    hasViewPricingPermission() &&
                                    !!product.Price &&
                                    (params.category === undefined ||
                                      ![
                                        "Photo Frames",
                                        "Gallery Frames",
                                      ].includes(params.category)) &&
                                    !product.ComingSoon
                                  }
                                  price={
                                    isPartner() &&
                                    hasViewPricingPermission() &&
                                    product.Price
                                      ? product.Price
                                      : undefined
                                  }
                                />
                              }
                            >
                              <ProductPromo
                                {
                                  // TODO: sloppy...
                                  ...(product as unknown as CataloguePromoModel)
                                    .data
                                }
                              />
                            </Show>
                          </div>
                        )}
                      </For>
                      {/* Safety in case IO doesn't trigger */}
                      <Show
                        when={
                          limitProducts() &&
                          filteredProducts()!.length > productLimit
                        }
                      >
                        <div
                          ref={(ref) => (lowerBound = ref)}
                          class="col-span-full flex justify-center items-center"
                        >
                          <button
                            class="text-sm text-roma-medium-grey border border-gray-300 rounded-full py-2 px-4 shadow-lg"
                            onClick={() => setLimitProducts(false)}
                          >
                            Scroll / Click for more products
                          </button>
                        </div>
                      </Show>
                    </Show>
                  </div>
                </Show>
              </main>
            </div>
            {/* TODO */}
            {/* <div class="border-t py-12">
              <RecentlyViewed />
            </div> */}
          </Body>
        </Show>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Shop;
