export enum PERMISSION {
  ACCESSDOCS = "AccessDocuments",
  RESETPASSWORD = "ResetPassword",
  VIEWINVENTORY = "ViewInventory",
  VIEWPRICING = "ViewPricing",
  VIEWPROFILE = "ViewProfile",
  PLACEORDER = "PlaceOrder",
  ORDERPRODUCT = "OrderProduct",
  VIEWPURCHASEHISTORY = "ViewPurchaseHistory",
  APPROVESAMPLES = "ApproveSamples",
  VIEWAPPROVESAMPLES = "ViewApproveSamples",
  VIEWARCHIVE = "ViewArchive",
  RETURNSFORM = "ReturnsForm",
  BPSAPPROVEORDERS = "BPSApproveOrders",
  BPSPLACEORDER = "BPSPlaceOrder",
  TEST = "NoOneHasThisPermission"
}

export type Permission = `${PERMISSION}`
export * from "./createPermissionChecker"


