import { Show, For } from "solid-js";
import { createAsync, type RouteDefinition } from "@solidjs/router";
import { useAccountDocuments } from "~/services/account";
import { DocumentPanel, AccountPanel } from "~/components/account";
import type { DocumentsByCategory } from "~/services/roma-api/account";
import { AuthBarrier } from "~/components/utility/AuthBarrier";

export const route = {
  preload: ()=>useAccountDocuments({ groupByCategory: true })
} satisfies RouteDefinition

export default function Documents() {
  const docs = createAsync(() =>
    useAccountDocuments({ groupByCategory: true })
  );

  return (
    <AccountPanel>
      <AuthBarrier permissions="ACCESSDOCS">
        <Show when={docs()}>
          <div class="pb-20 max-w-6xl">
            <h2 class="text-3xl font-bold border-b pb-5">Documents</h2>
            <div class="divide-y child:py-5">
              <For each={Object.entries(docs() as DocumentsByCategory)}>
                {([name, item]) => <DocumentPanel {...item} label={name} />}
              </For>
            </div>
          </div>
        </Show>
      </AuthBarrier>
    </AccountPanel>
  );
}
