import type { SetStoreFunction } from "solid-js/store";
import { PT } from "./products";

type RecentlyViewedActions = {
  get: () => RecentlyViewedProduct[];
  add: (value: RecentlyViewedProduct) => void;
  clear: () => void;
};
export type RecentlyViewedProduct = { mouldingNumber: string; type: PT };
export type RecentlyViewed = RecentlyViewedActions & (() => RecentlyViewedProduct[]);

export const createRecentlyViewed = (
  local: { recentlyViewed: RecentlyViewedProduct[] },
  setLocal: SetStoreFunction<{ recentlyViewed: RecentlyViewedProduct[] }>,
  max: number = 5
): RecentlyViewed => {
  const actions: RecentlyViewedActions = {
    get(): RecentlyViewedProduct[] {
      return local.recentlyViewed;
    },
    add(value: RecentlyViewedProduct) {
      if (!value) return;
      setLocal("recentlyViewed", (prev) => {
        const existing = prev.findIndex(item => 
          item.mouldingNumber === value.mouldingNumber && 
          item.type === value.type
        );
        const filtered =
          existing >= 0 ? prev.filter((_, i) => i !== existing) : prev;
        return [value, ...filtered].slice(0, max);
      });
    },
    clear() {
      setLocal("recentlyViewed", []);
    },
  };

  // Need to make this the base of the proxy so that recentlyViewed() can
  // be called without get (recentlyViewed.get())
  const callable = () => {
    return actions.get();
  };

  // Return proxy of the function
  return new Proxy(callable, {
    apply(_target, _this, _args): RecentlyViewedProduct[] {
      return actions.get();
    },
    get(_target, prop: string) {
      if (prop === "add" || prop === "clear" || prop === "get") {
        return actions[prop];
      }
      return actions.get();
    },
  }) as RecentlyViewed;
};
