// @ts-nocheck
import { TrackHandler, EventType } from "@solid-primitives/analytics";
import { isServer } from "solid-js/web";

export const trackGTAG: TrackHandler = (event, data) => {
  if (isServer || typeof gtag === "undefined" || typeof fbq === "undefined") {
    console.log(`Run event: ${event}`, data);
    return;
  }
  switch (event) {
    case EventType.Pageview:
      gtag("event", "page_view", {
        page_location: data.page_location
          ? data.page_location
          : window.location.href,
        language: "en-CA",
      });
      fbq("track", "PageView");
      break;
    case EventType.Social:
      gtag("event", "share", {
        method: data.network,
        content_type: data.action,
        item_id: data.target,
      });
      break;
    case EventType.Event: {
      const category = data.category;
      delete data.category;
      //! enable debug mode:
      // data.debug_mode = true;
      // console.log(
      //   "TRACKING: ",
      //   "event",
      //   "CATEGORY: ",
      //   category,
      //   "DATA: ",
      //   data
      // );
      gtag("event", category, data);
    }
  }
};

export const trackFBQ = <K extends keyof FBQEventMap>(
  evt: K,
  data: FBQEventMap[K]
) => {
  // @ts-expect-error
  if (isServer || typeof fbq === "undefined") {
    console.log("Pixel Event: ", evt, data);
    return;
  }

  if (evt === "Custom") {
    const eventName = (data as FBQEventMap["Custom"]).event;

    // @ts-expect-error
    fbq("trackCustom", eventName, { ...data });
    // TESTING:
    // console.log(">>>>> PIXEL TRACK CUSTOM >>>>>" );
    // console.log("Event: \n", eventName);
    // console.log("Data: \n", data)
    return;
  }
  // @ts-expect-error
  fbq("track", evt, data);
  // TESTING:
  // console.log(">>>>> PIXEL TRACK >>>>>" );
  // console.log("Event: \n", evt);
  // console.log("Data: \n", data)
};

interface FBQEventMap {
  AddToCart: AddToCartData;
  AddToWishlist: AddToCartData;
  InitiateCheckout: InitiateCheckoutData;
  Purchase: PurchaseData;
  Search: SearchData;
  ViewContent: ViewContentData;
  Custom: {
    event: string;
    [key: string]: string | number;
  };
}

interface AddToCartData {
  content_name?: string;
  content_category?: string;
  content_ids?: string[];
  contents?: { id: string; quantity: number }[];
  currency?: string;
  value?: number;
}

interface InitiateCheckoutData extends AddToCartData {
  num_items?: number;
}

interface PurchaseData {
  currency: string;
  value: number;
  content_ids?: string[];
  content_name?: string;
  content_type?: string;
  contents?: { id: string; quantity: number }[];
  num_items?: number;
}

interface SearchData {
  content_category?: string;
  content_ids?: string[];
  content_type?: string;
  contents?: { id: string; quantity: number }[];
  currency?: string;
}

interface ViewContentData {
  content_ids?: string;
  content_category?: string;
  content_name?: string;
  content_type?: string;
  contents?: { id: string; quantity: number }[];
  currency?: string;
  value?: number;
}
