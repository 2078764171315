import { For, type Component } from "solid-js";
import { Link } from "@solidjs/meta";

const fonts = [
  ["helvetica/HelveticaNeueLTPro-Bd.woff2", "font/woff2"],
  ["helvetica/HelveticaNeueLTPro-Bd.woff", "font/woff"],
  ["helvetica/HelveticaNeueLTPro-Md.woff2", "font/woff2"],
  ["helvetica/HelveticaNeueLTPro-Md.woff", "font/woff"],
  ["helvetica/HelveticaNeueLTPro-Lt.woff2", "font/woff2"],
  ["helvetica/HelveticaNeueLTPro-Lt.woff", "font/woff"],
  ["inter/Inter-Regular.woff2", "font/woff2"],
  ["inter/Inter-Regular.woff", "font/woff"],
];

export const Fonts: Component = () => {
  return (
    <For each={fonts}>
      {([path, format]) => (
        <Link
          rel="preload"
          type={format}
          href={`/fonts/${path}`}
          as="font"
          crossorigin="anonymous"
        />
      )}
    </For>
  );
};
