import type { SetStoreFunction } from "solid-js/store";

type RecentlySearchedActions = {
  get: () => string[];
  add: (value: string) => void;
  clear: () => void;
};

export type RecentlySearched = RecentlySearchedActions & (() => string[]);

export const createRecentlySearched = (
  local: { recentlySearched: string[] },
  setLocal: SetStoreFunction<{ recentlySearched: string[] }>,
  max: number = 5
): RecentlySearched => {
  const actions: RecentlySearchedActions = {
    get(): string[] {
      return local.recentlySearched;
    },
    add(value: string) {
      if (!value) return;
      setLocal("recentlySearched", (prev) => {
        const existing = prev.indexOf(value);
        const filtered =
          existing >= 0 ? prev.filter((_, i) => i !== existing) : prev;
        return [value, ...filtered].slice(0, max);
      });
    },
    clear() {
      setLocal("recentlySearched", []);
    },
  };

  // Need to make this the base of the proxy so that recentlySearched() can
  // be called without get (recentlySearched.get())
  const callable = () => {
    return actions.get();
  };

  // Return proxy of the function
  return new Proxy(callable, {
    apply(_target, _this, _args): string[] {
      return actions.get();
    },
    get(_target, prop: string) {
      if (prop === "add" || prop === "clear" || prop === "get") {
        return actions[prop];
      }
      return actions.get();
    },
  }) as RecentlySearched;
};
